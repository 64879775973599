<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import service from '../api/saleContractService'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {},
      createValidationSchema: Function
    }
  },
  methods: {
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([service.getMeta()])
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      this.fieldsById = _.keyBy(meta.fields, (field) => field.id)
      this.fieldsById.material.options = ctx.materialOptions
      this.fieldsById.customer.options = ctx.customerOptions
      this.fieldsById.quantityUnity.options = ctx.quantityUnityOptions
      this.fieldsById.contractPriceUnity.options = ctx.contractPriceUnityOptions
      this.fieldsById.usBucket.options = ctx.usBucketOptions
      this.fieldsById.port.options = ctx.portOptions
      this.fieldsById.incoterm.options = ctx.incotermOptions
      this.fieldsById.counterPartySector.options =
        ctx.contractCounterPartySectorOptions
      this.fieldsById.marketType.options.splice(
        this.fieldsById.marketType.options.indexOf(
          `application.enums.marketType.bt`
        ),
        1
      )
      this.generateValidationSchema()
      meta.details = metaDetails
      return meta
    },
    generateValidationSchema() {
      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)

        const portSchema = validationSchema.port
        validationSchema.port = portSchema.when(['marketType'], {
          is: (marketType) =>
            marketType?.value != null && marketType?.value != 'im',
          then: portSchema.required().label(translate('port')),
          otherwise: portSchema
        })
        const requiredCustomerReceiptExpectation = [
          'customerReceiptExpectation'
        ]
        requiredCustomerReceiptExpectation.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['penaltyType'], {
            is: (penaltyType) => penaltyType?.value == 'arrivalCustomerPeriod',
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })

        const requiredPenaltyAmountPercentage = ['penaltyAmountPercentage']
        requiredPenaltyAmountPercentage.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['penaltyType'], {
            is: (penaltyType) => penaltyType != null && penaltyType.value != '',
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })

        const requiredProjection = ['positionMonthProjection']
        requiredProjection.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['isContractProjection'], {
            is: (isContractProjection) => !!isContractProjection,
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })
        const requiredNotProjection = [
          'cropYear',
          'contractDate',
          'beginShipPeriod',
          'endShipPeriod',
          'minPercentageQuantityTolerance',
          'maxPercentageQuantityTolerance'
        ]
        requiredNotProjection.forEach((field) => {
          const fieldSchema = validationSchema[field]
          validationSchema[field] = fieldSchema.when(['isContractProjection'], {
            is: (isContractProjection) => !isContractProjection,
            then: fieldSchema.required().label(translate(field)),
            otherwise: fieldSchema
          })
        })
        return yup.object().shape(validationSchema)
      }
    },
    createHintPartialAllowed(meta) {
      const partialAllowedField = meta.fields.find(
        (field) => field.id === 'partialAllowed'
      )
      partialAllowedField.hint =
        'application.pages.saleContract.hints.partialAllowed'
    },
    marketTypeOnValueChange(changedValue, fields) {
      if (changedValue != 'im') {
        fields.penaltyType.hidden = false
        //fields.port.hidden = false
      } else {
        fields.penaltyType.hidden = true
        fields.penaltyType.value = null

        fields.customerReceiptExpectation.hidden = true
        fields.customerReceiptExpectation.value = null

        fields.penaltyAmountPercentage.hidden = true
        fields.penaltyAmountPercentage.value = null

        //fields.port.hidden = true
        //fields.port.value = null
      }
      this.metadata = { ...this.metadata }
    },
    penaltyTypeOnValueChange(changedValue, fields) {
      if (changedValue === 'shippingPeriod') {
        fields.penaltyAmountPercentage.hidden = false

        fields.customerReceiptExpectation.hidden = true
        fields.customerReceiptExpectation.value = null
      } else if (changedValue === 'arrivalCustomerPeriod') {
        fields.customerReceiptExpectation.hidden = false
        fields.penaltyAmountPercentage.hidden = false
      } else {
        fields.customerReceiptExpectation.hidden = true
        fields.customerReceiptExpectation.value = null

        fields.penaltyAmountPercentage.hidden = true
        fields.penaltyAmountPercentage.value = null
      }

      this.metadata = { ...this.metadata }
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta)
      this.createHintPartialAllowed(meta)
      this.metadata = meta
      this.metadata.fields.find(
        (field) => field.id === 'marketType'
      ).onValueChange = (changedValue, fields) => {
        if (changedValue) {
          let value =
            changedValue.value != null ? changedValue.value : changedValue
          this.marketTypeOnValueChange(value, fields)
        }
      }
      this.metadata.fields.find(
        (field) => field.id === 'penaltyType'
      ).onValueChange = (changedValue, fields) => {
        if (changedValue) {
          let value =
            changedValue.value != null ? changedValue.value : changedValue
          this.penaltyTypeOnValueChange(value, fields)
        }
      }
    })
  }
}
</script>
